import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InterfaceTable from "../../Table/InterfaceTable";
import { Row, Col, message, Form, Modal, Tooltip, Button } from "antd";
import Formatter from "../../Data/Domain/Formatter";
import ScriptOffsetsDetail from "./ScriptOffsetsDetail";
import {
  getScriptOffsetSelector,
  getScriptOffsetCountSelector,
} from "../Infrastructure/ScriptOffsets.reducer";
import moment from "moment";
import { EditOutlined, EyeOutlined } from "@ant-design/icons";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";

import { getScriptOffsetByUser } from "../Application/ScriptOffsets.business";
import SeeOriginScriptOffsets from "./SeeOriginScriptOffsets";
const ScriptOffsetsBoard = ({ userId, startDate, finishDate, searchQuery }) => {
  const scriptOffsets = useSelector(getScriptOffsetSelector);
  console.log(JSON.stringify(scriptOffsets));
  const totalCount = useSelector(getScriptOffsetCountSelector);

  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });

  const [loading, setLoading] = useState(false);
  const [isManual, setIsManual] = useState(null);
  const [scopeFilter, setScopeFilter] = useState([]);
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSeeOriginModalVisible, setIsSeeOriginModalVisible] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedSeeOrigin, setSelectedSeeOrigin] = useState(null);
  const [filters, setFilters] = useState({});

  const handleEdit = (record) => {
    setSelectedRecord(record);
    setIsModalVisible(true);
  };
  const handleSeeOrigin = (record) => {
    setSelectedSeeOrigin(record);
    setIsSeeOriginModalVisible(true);
  };

  const handleCopyJSON = () => {
    if (selectedSeeOrigin && selectedSeeOrigin.origin) {
      let originObject;

      if (typeof selectedSeeOrigin.origin === "string") {
        try {
          originObject = JSON.parse(selectedSeeOrigin.origin);
        } catch (error) {
          message.error("Se copió JSON con formato inválido");
          return;
        }
      } else {
        originObject = selectedSeeOrigin.origin;
      }

      const formatJSON = (obj) => {
        const jsonString = JSON.stringify(obj, null, 2);
        return jsonString.replace(/"([^"]+)":/g, "$1:");
      };

      const originJSON = formatJSON(originObject);

      navigator.clipboard
        .writeText(originJSON)
        .then(() => {
          message.success("JSON copiado ");
        })
        .catch((err) => {
          message.error("Error al copiar JSON");
        });
    }
  };
  const columnsScript = [
    {
      title: "Clasificación manual",
      dataIndex: "is_manual",
      key: "is_manual",
      render: (is_manual) => (is_manual ? "SI" : "NO"),
      filters: [
        {
          text: "SI",
          value: true,
        },
        {
          text: "NO",
          value: false,
        },
      ],
    },
    {
      title: "Número de Documento",
      dataIndex: "documentNumber",
      key: "documentNumber",
    },
    {
      title: "Área",
      dataIndex: "area",
      key: "area",
      render: (area) => (area.length > 0 ? area.join(", ") : "Sin área"),
    },
    {
      title: "Proyecto",
      dataIndex: "project",
      key: "project",
      render: (project) =>
        project.length > 0 ? project.join(", ") : "Sin proyecto",
    },
    {
      title: "ID del Proveedor",
      dataIndex: "supplierId",
      key: "supplierId",
    },
    {
      title: "Nombre de cuenta",
      dataIndex: "accountName",
      key: "accountName",
    },
    {
      title: "Consumo",
      dataIndex: "consumption",
      key: "consumption",
    },
    {
      title: "Monto total",
      dataIndex: "amountTotal",
      key: "amountTotal",
    },
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
      filters: [
        { text: "Scope 1", value: "scope 1" },
        { text: "Scope 2", value: "scope 2" },
        { text: "Scope 3", value: "scope 3" },
      ],
    },
    {
      title: "Categoría",
      dataIndex: "subScope",
      key: "subScope",
    },
    {
      title: "Subcategoría",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Factor",
      dataIndex: "factor",
      key: "factor",
    },
    {
      title: "KG Totales CO₂",
      dataIndex: "total_co2_kg",
      key: "total_co2_kg",
    },
    {
      title: "Ton. Totales de CO₂",
      dataIndex: "total_co2_tons",
      key: "total_co2_tons",
    },
    {
      title: "Fecha",
      dataIndex: "accountingDate",
      key: "accountingDate",
      render: (accountingDate) => {
        const date = accountingDate
          ? moment(accountingDate).format("YYYY-MM-DD")
          : "Fecha no disponible";
        return date;
      },
    },

    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <div className="scriptOff-actions">
            <div>
              <Tooltip title="Editar Script Offset">
                <EditOutlined
                  style={{ color: "red" }}
                  onClick={() => handleEdit(record)}
                />
              </Tooltip>
            </div>
            <div className="button-origin">
              <Tooltip title="Ver Origen">
                <EyeOutlined
                  style={{ color: "red" }}
                  onClick={() => handleSeeOrigin(record)}
                />
              </Tooltip>
            </div>
          </div>
        );
      },
    },
  ];

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const filters = searchQuery
        ? { q: searchQuery }
        : {
            start_date: startDate,
            finish_date: finishDate,
            user: userId,
            ...params,
          };

      let combinedParams = { ...params, filter: filters };
      if (!searchQuery) {
        combinedParams = {
          ...combinedParams,
          field_sort: params.field_sort || "date",
          sort: params.sort || "descending",
        };
      }
      if (isManual !== null) {
        filters.is_manual = isManual;
      }
      if (scopeFilter.length > 0) {
        filters.scope = scopeFilter;
      }

      setFilters(filters);

      await getScriptOffsetByUser(userId, combinedParams);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
    } catch (error) {
      message.error("error");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = values.limit } = newPagination;
    const field_sort = !searchQuery ? sorter.field : null;
    const sort = !searchQuery
      ? sorter.order === "ascend"
        ? "ascending"
        : "descending"
      : null;

    const isManualFilter = filters.is_manual ? filters.is_manual : null;
    const scopeFilterValues = filters.scope || [];

    setIsManual(isManualFilter);
    setScopeFilter(scopeFilterValues.length > 0 ? scopeFilterValues : []);

    setValues({
      ...values,
      limit,
      page: page - 1,
    });

    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      field_sort,
      sort,
      page: page - 1,
      dates: [],
    });

    fetchData(clean);
  };

  useEffect(() => {
    if (userId) {
      fetchData({ page: values.page, limit: values.limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeFilter, values.page, values.limit, userId, searchQuery]);

  useEffect(() => {
    if (userId) {
      fetchData({ page: values.page, limit: values.limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isManual]);

  useEffect(() => {
    if (userId) {
      fetchData({ page: values.page, limit: values.limit });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, finishDate, userId]);

  return (
    <div>
      <Row style={{ marginBottom: 20 }}>
        <Col span={12}>
          <h2>Script Offsets</h2>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <ExportButtonElement
            type={"scriptOffsets"}
            text={"Exportar"}
            filter={{
              user: userId,
              start_date: startDate,
              finish_date: finishDate,
              is_manual: isManual,
              scope: scopeFilter,
              q: searchQuery,
            }}
          />
        </Col>
      </Row>
      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          onChange: (current) => {
            setValues({
              ...values,
              page: current - 1,
            });
            fetchData({
              page: current - 1,
              limit: values.limit,
            });
          },
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            setValues({
              ...values,
              limit: size,
              page: current - 1,
            });
            fetchData({
              page: current - 1,
              limit: size,
            });
          },
        }}
        data={scriptOffsets}
        columns={columnsScript}
        loading={loading}
      />
      {selectedRecord && (
        <Modal
          footer={null}
          closable={false}
          visible={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          title="Editar Script Offset"
        >
          <ScriptOffsetsDetail
            setIsModalVisible={setIsModalVisible}
            record={selectedRecord}
            form={form}
            userId={userId}
            Offid={selectedRecord.id || selectedRecord._id}
            currentPagination={{ page: values.page, limit: values.limit }}
          />
        </Modal>
      )}
      {selectedSeeOrigin && (
        <Modal
          width="auto"
          centered
          footer={
            <Button type="primary" onClick={handleCopyJSON}>
              Copiar
            </Button>
          }
          closable={true}
          visible={isSeeOriginModalVisible}
          onCancel={() => setIsSeeOriginModalVisible(false)}
          title="Origen"
        >
          <SeeOriginScriptOffsets scriptOffsets={selectedSeeOrigin.origin} />
        </Modal>
      )}
    </div>
  );
};

export default ScriptOffsetsBoard;
