import React, { useState } from "react";
import { Table, Button, Modal } from "antd";

export default function NftsBoard({ data }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [carbonFluxData, setCarbonFluxData] = useState(null);

  const handleModalOpen = (record) => {
    setCarbonFluxData(record);
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  const columns = [
    { title: "Code", dataIndex: "assetCode", key: "assetCode" },
    { title: "Hash de Blockchain", dataIndex: "blockchainHash", key: "blockchainHash" },
    { title: "Hash de IPFS", dataIndex: "ipfsHash", key: "ipfsHash" },
    { title: "NFT/SBT", dataIndex: "isSbt", key: "isSbt" },
    { title: "Proyecto", dataIndex: "projectName", key: "projectName" },
    { title: "Usuario", dataIndex: "username", key: "username" },
    { title: "Fecha de compra", dataIndex: "boughtAt", key: "boughtAt" },
    { title: "Fecha de Offset", dataIndex: "usedAt", key: "usedAt" },
    { title: "Usado por", dataIndex: "usedBy", key: "usedBy" },
    { title: "Periodo de Offset", dataIndex: "offsetPeriod", key: "offsetPeriod" },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) => (
        <div>
          <Button onClick={() => handleModalOpen(record)}>
            Ver CarbonFlux
          </Button>
          {record.isSbt === "SBT" && (
            <Button href={record.certificate} target="_blank" style={{ marginLeft: 8 }}>
              Ver Certificado
            </Button>
          )}
        </div>
      ),
    },
  ];

  return (
    <>
      <Table dataSource={data} columns={columns} rowKey="assetCode" pagination={{ pageSize: 10 }} />
      <Modal
        title="Información de CarbonFlux"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
      >
        <p>{carbonFluxData ? JSON.stringify(carbonFluxData) : "No data"}</p>
      </Modal>
    </>
  );
}