import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "stationData",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setStationData: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    countStationData: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
  },
});

export const { setStationData, countStationData } = slice.actions;

export default slice.reducer;

export const getStationDataSelector = (store) => {
  const stationData = store.stationData;

  return stationData?.list || [];
};
export const getStationDataCountSelector = (store) => store.stationData.count;
