import React from "react";
import { Empty } from "antd";

const SeeOriginScriptOffsets = ({ scriptOffsets }) => {
  if (!scriptOffsets || Object.keys(scriptOffsets).length === 0) {
    return <Empty description="No hay datos disponibles" />;
  }

  let parsedScriptOffsets;

  if (typeof scriptOffsets === "string") {
    try {
      parsedScriptOffsets = JSON.parse(scriptOffsets);
    } catch (error) {
      console.error(error);
      return <Empty description="Los datos son inválidos" />;
    }
  } else {
    parsedScriptOffsets = scriptOffsets;
  }

  const formatJSON = (obj) => {
    const jsonString = JSON.stringify(obj, null, 2);
    return jsonString.replace(/"([^"]+)":/g, "$1:");
  };

  return <pre>{formatJSON(parsedScriptOffsets)}</pre>;
};

export default SeeOriginScriptOffsets;
