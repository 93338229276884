import store from "../../Redux/store";
import { setStationData, countStationData } from "./StationData.reducer";

export function saveListStationData(records) {
  //console.log("Saving list in Station Data:", records);
  store.dispatch(setStationData(records));
}

export function saveCountStationData(count) {
  //console.log("Saving count in Station Data:", count);
  store.dispatch(countStationData(count));
}

export default store;
