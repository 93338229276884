import { getStationDataLogic } from "./StationData.logic";
import {
  saveListStationData,
  saveCountStationData,
} from "../Infrastructure/StationData.store";

export async function getStationData(params) {
  const { count, resources: stationData } = await getStationDataLogic(params);
  saveListStationData(stationData);
  saveCountStationData(count);
}
