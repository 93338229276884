import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getReport(type, filter) {
  const { user, start_date, finish_date, is_manual, scope, q } = filter || {};

  const filterString = `?user=${user || ""}&start_date=${
    start_date || ""
  }&finish_date=${finish_date || ""}&is_manual=${is_manual || ""}&scope=${
    scope || ""
  }&q=${encodeURIComponent(q || "")}`;

  return await new API(
    URL.buildUrl(`reports/${type}${filterString}&is_report=true`)
  ).post();
}
