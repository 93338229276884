import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "carbonFlux",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setCarbonFlux: (state, { payload: list }) => ({ ...state, list }),
    countCarbonFlux: (state, { payload: count }) => ({ ...state, count }),
  },
});

export const { setCarbonFlux, countCarbonFlux } = slice.actions;
export default slice.reducer;

export const getCarbonFluxSelector = (store) => store.carbonFlux.list;
export const getCarbonFluxCountSelector = (store) => store.carbonFlux.count;