class StationData {
    constructor(data) {
      this.id = data.carbon_real_id;
      this.co2FluxGM2 = data.co2_flux_g_m2;
      this.co2Flux = data.co2_flux;
      this.co2FluxHa = data.co2_flux_t_ha;
      this.datetime = data.datetime;
      this.qcCo2Flux = data.qc_co2_flux;
      this.station = data.station;
    }
  
    static fromJSON(data) {
      return new StationData(data);
    }
  }
  
  export default StationData;
