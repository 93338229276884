import Formatter from "../../Data/Domain/Formatter";

class CarbonFlux {
  constructor({
    constellation_name,
    created_at,
    gross_contribution,
    processed,
    productive_area_size_m2,
    project_name,
    remainder,
    station,
    station_data,
    total_co2,
  }) {
    this.constellationName = constellation_name;
    this.createdAt = created_at;
    this.grossContribution = gross_contribution;
    this.processed = processed;
    this.productiveAreaSize = productive_area_size_m2;
    this.projectName = project_name;
    this.remainder = remainder;
    this.station = station;
    this.stationData = station_data;
    this.totalCo2 = total_co2;
  }

  get processedString() {
    return this.processed ? "Sí" : "No";
  }

  get formattedProductiveAreaSize() {
    return Formatter.formatNumber(this.productiveAreaSize);
  }

  get formattedGrossContribution() {
    return Formatter.formatNumber(this.grossContribution);
  }

  get formattedRemainder() {
    return Formatter.formatNumber(this.remainder);
  }

  get formattedTotalCo2() {
    return Formatter.formatNumber(this.totalCo2);
  }

  static fromJSON(data) {
    return new CarbonFlux(data);
  }
}

export default CarbonFlux;
