import React, { useEffect, useState, useRef } from "react";
import { Card, Input } from "antd";
import SearcherScriptOffsets from "./SearcherScriptOffsets";
import { getUsers } from "../../Users/Application/Users.business";
import ScriptOffsetsBoard from "./ScriptOffsetsBoard";
import { getScriptOffsetByUser } from "../Application/ScriptOffsets.business";
import ScriptOffset from "../../Users/Domain/User";
import RangePicker from "../../Layout/DesignSystem/Picker/Picker";
import moment from "moment";
import "./ScriptOffsets.css";

const ScriptOffsets = () => {
  const [userId, setUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [values, setValues] = useState({
    id: "",
    start_date: null,
    finish_date: null,
  });

  const dates = useRef([]);

  const onDateChange = (changed_values) => {
    if (!changed_values || changed_values.length === 0) {
      setValues({
        ...values,
        start_date: null,
        finish_date: null,
      });
    } else {
      dates.current = changed_values.map((value) =>
        moment(value).format("YYYY-MM-DD")
      );
      setValues({
        ...values,
        start_date: dates.current[0],
        finish_date: dates.current[1],
      });
    }
  };

  const handleInputChange = (e) => {
    setSearchQuery(e.target.value);
  };
  useEffect(() => {
    getUsers();
    if (userId) {
      getScriptOffsetByUser(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <div>
      {" "}
      <div className="grid-scriptOffsets">
        <div className="grid-searcher-script">
          <div>
            <SearcherScriptOffsets setId={setUserId} />
          </div>
          <div className="picker-container">
            <RangePicker onChange={onDateChange} />
          </div>
          <div className="picker-container">
            <Input
              placeholder="N° documento, ID de proveedor, cuenta, área o proyecto"
              value={searchQuery}
              onChange={handleInputChange}
            />{" "}
          </div>
        </div>
        <div></div>
      </div>
      <div className="grid-script">
        <div>
          <Card className="card-content-script">
            {userId && (
              <ScriptOffsetsBoard
                userId={userId}
                ScriptOffset={ScriptOffset}
                getScriptOffsetByUser={getScriptOffsetByUser}
                startDate={values.start_date}
                finishDate={values.finish_date}
                searchQuery={searchQuery}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ScriptOffsets;
