import admins from "../Administrator/Infrastructure/Administrators.reducer";
import areas from "../Areas/Infrastructure/Areas.reducer";
import bankAccounts from "../CompaniesBankDetails/Infrastructure/CompaniesBankDetails.reducer";
import categories from "../Categories/Infrastructure/Categories.reducer";
import categoriesEmission from "../EmissionCategory/Infrastructure/EmissionCategory.reducer";
import collaborators from "../Collaborators/Infrastructure/Collaborators.reducer";
import companies from "../Companies/Infrastructure/Companies.reducer";
import documents from "../DocumentUpload/Infrastructure/DocumentUpload.reducer";
import fees from "../Commissions/Infrastructure/Commissions.reducer";
import offers from "../Offers/Infrastructure/Offers.reducer";
import offsets from "../DashboardOffset/Infrastructure/DashboardOffset.reducer";
import projects from "../Projects/Infrastructure/Projects.reducer";
import reductions from "../Saving/Infrastructure/Saving.reducer";
import repositoryProjects from "../RepositoryProjects/Infrastructure/RepositoryProjects.reducer";
import stats from "../Dashboard/Infrastructure/Dashboard.reducer";
import subcategories from "../Subcategories/Infrastructure/Subcategories.reducer";
import suppliers from "../Suppliers/Infrastructure/Suppliers.reducer";
import transactions from "../Transactions/Infrastructure/Transactions.reducer";
import users from "../Users/Infrastructure/Users.reducer";
import dataUsers from "../DataUser/Infrastructure/DataUser.reducer";
import permissions from "../Permissions/Infrastructure/Permissions.reducer";
import project from "../Owners/Infrastructure/Owners.reducer";
import keys from "../Keys/Infrastructure/Keys.reducer";
import scriptOffsets from "../ScriptOffsets/Infrastructure/ScriptOffsets.reducer";
import stationData from "../StationData/Infrastructure/StationData.reducer";
import carbonFlux from "../CarbonFlux/Infrastructure/CarbonFlux.reducer";
import nfts from "../NFTS/Infrastructure/Nfts.reducer";


const reducer = {
  admins,
  areas,
  bankAccounts,
  categories,
  categoriesEmission,
  collaborators,
  companies,
  documents,
  fees,
  offers,
  offsets,
  projects,
  reductions,
  repositoryProjects,
  stats,
  subcategories,
  suppliers,
  transactions,
  users,
  dataUsers,
  permissions,
  project,
  keys,
  stationData,
  scriptOffsets,
  carbonFlux,
  nfts,
};

export default reducer;
