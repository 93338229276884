import React from "react";
import { Button, message } from "antd";
import "antd/dist/antd.min.css";
import { DownloadOutlined } from "@ant-design/icons";
import "./Button.css";
import { getReport } from "../../../Reports/Application/Reports.business";

const ExportButtonElement = ({
  text,
  htmlType,
  block,
  onClick,
  loading,
  type,
  filter,
}) => {
  const goReport = async () => {
    try {
      const response = await getReport(type, filter);
      const { url } = response;
      window.open(url, "_blank");
      message.success("Se generó el reporte");
    } catch (error) {
      message.error("Algo ha fallado al generar el reporte");
    }
  };

  return (
    <Button
      className={"button-white-blue"}
      onClick={goReport}
      icon={<DownloadOutlined />}
      htmlType={htmlType}
      block={block}
      loading={loading}
    >
      {text}
    </Button>
  );
};

export default ExportButtonElement;
