import Response from "../../Data/Domain/Response";
import User from "../Domain/User";
import {
  getUsers,
  getUser,
  deleteUser,
  updateEnabledUser,
  updateLumens,
  updateUserStatus
} from "./User.service";

export async function getUsersRepository(params) {
  const data = await getUsers(params);
  return new Response(data, User).getMultiple();
}

export async function getUserRepository(id) {
  const data = await getUser(id);
  return new Response(data, User).getSingle();
}

export async function deleteUserRepository(id, params) {
  const data = await deleteUser(id, params);
  return new Response(data, User).onlyStatus();
}

export async function updateEnabledRepository(id, params) {
  const data = await updateEnabledUser(id, params);
  return new Response(data, User).onlyStatus();
}

export async function updateLumensRepository(id, params) {
  const data = await updateLumens(id, params);
  return new Response(data, User).onlyStatus();
}

export async function updateUserStatusRepository(userId, verifiedStatus) {
  const data = await updateUserStatus(userId, verifiedStatus);
  return new Response(data, User).onlyStatus();
}
