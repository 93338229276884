import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getUsers(params) {
  return await new API(URL.setParams(URL.buildUrl("users"), params)).get();
}

export async function getUser(id) {
  return await new API(URL.setId(URL.buildUrl("users"), id)).get();
}

export async function deleteUser(id, { op }) {
  let url = URL.setId(URL.buildUrl("users"), id);
  if (op === "delete") {
    url = URL.setQuery(url, { key: "full", value: true });
  }
  return await new API(url).delete();
}

export async function updateEnabledUser(id, data) {
  const url = URL.setId(URL.buildUrl("users"), id);
  return await new API(url).put(data);
}

export async function updateLumens(id, lumens) {
  return await new API(URL.buildUrl(`users/${id}/lumens`)).post(lumens);
}

export const updateUserStatus = async (userId, verifiedStatus) => {
  const url = `${URL.setId(URL.buildUrl("users"), userId)}/validators`;
  console.log("URL construida:", url);
  return await new API(url).put({ verified: verifiedStatus });
};