import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
import { Title } from "../../Utils/UI/Text"; 
import { getNfts } from "../Application/Nfts.business";
import { getNftsSelector } from "../Infrastructure/Nfts.reducer";
import NftsBoard from "./NftsBoard";

const { Content } = Layout;

export default function Nfts() {
  const nftsData = useSelector(getNftsSelector);

  useEffect(() => {
    const fetchNfts = async () => {
      await getNfts();
    };
    fetchNfts();
  }, []);

  return (
    <Content style={{ padding: "20px" }}>
      <Row style={{ marginBottom: 16 }} justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ margin: 0 }}>
            NFTs
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <NftsBoard data={nftsData} />
        </Col>
      </Row>
    </Content>
  );
}