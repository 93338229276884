import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, Button } from "antd";
import moment from "moment"; 
import { getStationData } from "../Application/StationData.business";
import { getStationDataSelector } from "../Infrastructure/StationData.reducer";

export default function StationDataBoard() {
  const stationData = useSelector(getStationDataSelector);
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    await getStationData({});
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const formatNumber = (value) =>
    value ? parseFloat(value).toFixed(4) : "0.0000";

  const formattedData = stationData.map((item) => ({
    ...item,
    co2FluxGM2: formatNumber(item.co2FluxGM2),
    co2Flux: formatNumber(item.co2Flux),
    co2FluxHa: formatNumber(item.co2FluxHa),
  }));

  const columns = [
    { title: "ID de Carbon Real", dataIndex: "id", key: "id" },
    { title: "CO2 Flux g/m²", dataIndex: "co2FluxGM2", key: "co2FluxGM2" },
    { title: "CO2 Flux", dataIndex: "co2Flux", key: "co2Flux" },
    { title: "CO2 Flux ha", dataIndex: "co2FluxHa", key: "co2FluxHa" },
    {
      title: "Fecha",
      dataIndex: "datetime",
      key: "datetime",
      render: (datetime) => {
        const date = datetime
          ? moment(datetime).format("YYYY-MM-DD")
          : "Fecha no disponible";
        return date;
      },
    },
    { title: "Calidad", dataIndex: "qcCo2Flux", key: "qcCo2Flux" },
    { title: "Estación", dataIndex: "station", key: "station" },
    {
      title: "Acción",
      key: "action",
      render: () => <Button>Exportar CSV</Button>,
    },
  ];

  return (
    <Table
      dataSource={formattedData}
      columns={columns}
      loading={loading}
      rowKey="id"
      pagination={{ pageSize: 10 }}
    />
  );
}
