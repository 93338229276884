class ScriptOffset {
  constructor({
    _id,
    id,
    is_manual,
    supplierId,
    accountName,
    consumption,
    amountTotal,
    area,        
    project,
    scope,
    subScope,
    subCategory,
    factor,
    total_co2_kg,
    total_co2_tons,
    accountingDate,
    documentNumber,
    origin
  }) {
    this.id = _id || id;
    this.is_manual = is_manual;
    this.supplierId = supplierId;
    this.accountName = accountName;
    this.consumption = consumption;
    this.scope = scope;
    this.subScope = subScope;
    this.amountTotal = amountTotal;
    this.area = area || [];  
    this.project = project || [];
    this.subCategory = subCategory;
    this.factor = factor;
    this.total_co2_kg = total_co2_kg;
    this.total_co2_tons = total_co2_tons;
    this.accountingDate = accountingDate;
    this.documentNumber = documentNumber;
    this.origin = origin;

  }
}

export default ScriptOffset;

