import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "nfts",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setNfts: (state, { payload: list }) => ({ ...state, list }),
    countNfts: (state, { payload: count }) => ({ ...state, count }),
  },
});

export const { setNfts, countNfts } = slice.actions;
export default slice.reducer;

export const getNftsSelector = (store) => store.nfts.list;
export const getNftsCountSelector = (store) => store.nfts.count;