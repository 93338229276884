import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
import { Title } from "../../Utils/UI/Text"; 
import { getCarbonFlux } from "../Application/CarbonFlux.business";
import { getCarbonFluxSelector } from "../Infrastructure/CarbonFlux.reducer";
import CarbonFluxBoard from "./CarbonFluxBoard";

const { Content } = Layout;

export default function CarbonFlux() {
  const carbonFluxData = useSelector(getCarbonFluxSelector);

  useEffect(() => {
    const fetchCarbonFlux = async () => {
      await getCarbonFlux();
    };
    fetchCarbonFlux();
  }, []);

  return (
    <Content style={{ padding: "20px" }}>
      <Row style={{ marginBottom: 16 }} justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ margin: 0 }}>
            CarbonFlux
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <CarbonFluxBoard data={carbonFluxData} />
        </Col>
      </Row>
    </Content>
  );
}