import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
import { Title } from "../../Utils/UI/Text";
import { getStationData } from "../Application/StationData.business";
import { getStationDataSelector } from "../Infrastructure/StationData.reducer";
import StationDataBoard from "./StationDataBoard";

const { Content } = Layout;

export default function StationData() {
  const stationData = useSelector(getStationDataSelector);

  useEffect(() => {
    const fetchStationData = async () => {
      await getStationData();
    };

    fetchStationData();
  }, []);

  return (
    <Content style={{ padding: "20px" }}>
      <Row style={{ marginBottom: 16 }} justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ margin: 0 }}>
            StationData
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <StationDataBoard data={stationData} />
        </Col>
      </Row>
    </Content>
  );
}
