import React from "react";
import { Table } from "antd";

export default function CarbonFluxBoard({ data }) {
  const columns = [
    { title: "Fecha de Creación", dataIndex: "createdAt", key: "createdAt" },
    {
      title: "Procesado",
      dataIndex: "processedString",
      key: "processedString",
    },
    {
      title: "Constelación",
      dataIndex: "constellationName",
      key: "constellationName",
    },
    { title: "Proyecto", dataIndex: "projectName", key: "projectName" },
    { title: "Estación", dataIndex: "station", key: "station" },
    {
      title: "Dato de la estación",
      dataIndex: "stationData",
      key: "stationData",
    },
    {
      title: "Área productiva",
      dataIndex: "formattedProductiveAreaSize",
      key: "formattedProductiveAreaSize",
    },
    {
      title: "Contribución",
      dataIndex: "formattedGrossContribution",
      key: "formattedGrossContribution",
    },
    {
      title: "Saldo",
      dataIndex: "formattedRemainder",
      key: "formattedRemainder",
    },
    {
      title: "CO2 Total",
      dataIndex: "formattedTotalCo2",
      key: "formattedTotalCo2",
    },
  ];

  return (
    <Table
      dataSource={data}
      columns={columns}
      rowKey="id"
      pagination={{ pageSize: 10 }}
    />
  );
}
